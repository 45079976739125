import React from 'react';
import BestService from './BestService/BestService';

export default function BestServiceLayout() {
  return (
    <div className='bestService'>
       <BestService></BestService>
    </div>
  )
}
