import React from "react";
import Button from "../UIComponent/Button";
import './callforassistance.scss';

export default function CallForAssistance() {
  const heading = "Not Sure Which Doctor to consult?";
  const subHeading = "Let us help you";
  const CTAText = "Call Us";
  return (
    <div className="global-call-assistance">
      <div className="text-wrapper">
        <h2>{heading}</h2>
        <span>{subHeading}</span>
      </div>
      <div className="button-wrapper">
        <Button primaryButton={true}>{CTAText}</Button>
      </div>
    </div>
  );
}
