const apiKey = process.env;

async function getBestHospitals() {
    const apiUrl = apiKey.REACT_APP_API_URL;
  const data = await fetch(`${apiUrl}/getAllHospitals`);
  const result = await data.json();
  return result.data
}

async function getBestDoctors(){
    const apiUrl = apiKey.REACT_APP_API_URL;
    const data = await fetch(`${apiUrl}/getDoctors`);
  const result = await data.json();
  return result.data
}
async function getBestPathology(){
  const apiUrl = apiKey.REACT_APP_API_URL;
  const data = await fetch(`${apiUrl}/getAllPathology`);
const result = await data.json();
return result.data
}


module.exports = { getBestHospitals, getBestDoctors,getBestPathology }
