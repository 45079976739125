import React, { useEffect, useReducer, useState } from "react";
import "./offerSubscription.scss";
import bgImage from "../../assets/Images/OfferSectionImage/offer-bg-image.png";
import InputBox from "../../UI/UIComponent/Input";
import Button from "../../UI/UIComponent/Button";

const FirstNameReducer = (state, action) => {
  if (action.type === "user_firstName") {
    return { value: action.value, isFirstNameValid: action.value.length > 0 };
  }
  if (action.type === "user_firstName_blur") {
    return { value: state.value, isFirstNameValid: state.value.length > 0 };
  }
};
const LastNameReducer = (state, action) => {
  if (action.type === "user_lastName") {
    return { value: action.value, isLastNameValid: action.value.length > 0 };
  }
};
const EmailReducer = (state, action) => {
  if (action.type === "user_email") {
    return { value: action.value, isEmailValid: action.value.includes("@") };
  }
};
const PhoneNoReducer = (state, action) => {
  if (action.type === "user_phone") {
    return { value: action.value, isPhoneNoValid: action.value.length === 10 };
  }
};
export default function OfferSubscription() {
  // const [setFirstName,dispatchFirstNameEvent] = useReducer(FirstNameReducer,{
  //   value:'',
  //   isFirstNameValid:null
  // });
  // const [setLastName,dispatchLastNameEvent] = useReducer(LastNameReducer,{
  //   value:'',
  //   isLastNameValid:null
  // });
  // const [setEmail,dispatchEmailEvent] = useReducer(EmailReducer,{
  //   value:'',
  //   isLastNameValid:null
  // });
  // const [setPhone,dispatchPhoneNumberEvent] = useReducer(PhoneNoReducer,{
  //   value:'',
  //   isPhoneNoValid:null
  // });

  // const [fromValid,setFromValid] = useState(false);

  // const handleButtonClick = (e) =>{
  //   e.preventDefault();
  //   let modalRef = document.getElementById('commonModal');
  //   if(fromValid){
  //     modalRef.classList.add('show');
  //   }

  // }
  // const onFirstNameChange = (e) =>{
  //   dispatchFirstNameEvent({
  //     value:e.target.value,
  //     type:"user_firstName"
  //   });
  // }
  // const onLastNameChange = (e) =>{
  //   dispatchLastNameEvent({
  //     value:e.target.value,
  //     type:"user_lastName"
  //   });
  // }
  const onEmailChange = (e) => {
    // dispatchEmailEvent({
    //   value:e.target.value,
    //   type:"user_email"
    // });
  };
  // const onPhoneNumberChange = (e) =>{
  //   dispatchPhoneNumberEvent({
  //     value:e.target.value,
  //     type:"user_phone"
  //   });
  // }
  // const handleFirstNameRelease = (e) =>{
  //   dispatchFirstNameEvent({
  //     value:e.target.value,
  //     type:"user_firstName_blur"
  //   });

  // }
  // const {isFirstNameValid:firstNamevalid} = setFirstName;
  // const {isLastNameValid:LastNamevalid} = setLastName;
  // const {isEmailValid:emailvalid} = setEmail;
  // const {isPhoneNoValid:phonevalid} = setPhone;

  // useEffect(()=>{
  //   if(!firstNamevalid){

  //     document.getElementById('firstNameId').classList.remove('notVis');
  //   }
  //   if(!LastNamevalid){

  //     document.getElementById('LastNameId').classList.remove('notVis');
  //   }
  //   if(!emailvalid){

  //     document.getElementById('EmailId').classList.remove('notVis');
  //   }
  //   if(!phonevalid){

  //     document.getElementById('PhoneNolId').classList.remove('notVis');
  //   }
  //   setFromValid(firstNamevalid && LastNamevalid && emailvalid && phonevalid);

  // },[firstNamevalid,LastNamevalid,emailvalid,phonevalid]);

  return (
    <div className="offerSubscription-section">
      <div className="container">
        <div className="row">
          <div class="col-lg-12">
            <div className="heading">
              <h2>Subscribe to our newsletter</h2>
            </div>
            <form onSubmit="">
             
              <div className="row custom-row">
                <div className="col-lg-10">
                  <InputBox
                    inputId="idEmail"
                    inputPlaceholder="Please Enter You Email"
                    onChange=""
                    onBlur=""
                    handleonChange={onEmailChange}
                  ></InputBox>
                  <span id="EmailId" className="notvis">
                    Please Check your Email
                  </span>
                </div>
                <div className="col-lg-2">
                  <div className="button-section">
                    <Button primaryButton={true}>Submit</Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* <div className="col-lg-4">
            <img src={bgImage} alt="Rever Health"></img>
          </div> */}
        </div>
      </div>
    </div>
  );
}
