import React from "react";
import BannerContent from "../../StaticData/StaticBannerContent.json";
import ServiceBanner from "../../assets/Images/Homepage/static-service-banner.png";
import ServiceBannerMob from "../../assets/Images/Homepage/static-service-banner-mob.jpg";
import emergencyIcon from "../../assets/Images/Svg/Emergency-Icon.png";
import doctorConIcon from "../../assets/Images/Svg/Doctor-consultation-icon.png";
import surgeryConIcon from "../../assets/Images/Svg/surgery-consultation-icon.png";
import hospitalAdmissionCon from "../../assets/Images/Svg/hospital-admission-icon.png";
import diagonosticsConIcon from "../../assets/Images/Svg/diagonostics-consultation-icon.png";
import medicationConIcon from "../../assets/Images/Svg/medicine-consultation-icon.png";
import "./staticBanner.scss";
import Button from "../../UI/UIComponent/Button";

export default function StaticBanner() {
  return (
    <div className="static-banner">
      <img
        alt="RHS services"
        src={ServiceBanner}
        className="banner-img w-100"
      ></img>
      <img
        alt="RHS services"
        src={ServiceBannerMob}
        className="banner-img-mob w-100"
      ></img>
      <div className="banner-content">
        <div className="heading">
          <span>{BannerContent.ServiceBanner.Heading}</span>
        </div>
        <div className="subHeading">
          <span>{BannerContent.ServiceBanner.Subheading}</span>
        </div>
        <div className="conatiner services-section">
          <div className="custom-row row">
            <div className="col-6">
              <div className="d-flex">
                <span>
                  <img alt="RHS emergency Contact" src={emergencyIcon} className="icon"></img>
                </span>
                <span>
                  {BannerContent.ServiceBanner["Service-1"]["service-desc"]}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex">
                <span>
                  <img
                    alt="RHS emergency Contact"
                    src={hospitalAdmissionCon} className="icon"
                  ></img>
                </span>
                <span>
                  {BannerContent.ServiceBanner["Service-2"]["service-desc"]}
                </span>
              </div>
            </div>
          </div>
          <div className="custom-row row">
            <div className="col-6">
              <div className="d-flex">
                <span>
                  <img alt="RHS emergency Contact" src={doctorConIcon} className="icon"></img>
                </span>
                <span>
                  {BannerContent.ServiceBanner["Service-3"]["service-desc"]}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex">
                <span>
                  <img alt="RHS emergency Contact" src={surgeryConIcon} className="icon"></img>
                </span>
                <span>
                  {BannerContent.ServiceBanner["Service-4"]["service-desc"]}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="d-flex">
                <span>
                  <img
                    alt="RHS emergency Contact"
                    src={diagonosticsConIcon} className="icon"
                  ></img>
                </span>
                <span>
                  {BannerContent.ServiceBanner["Service-5"]["service-desc"]}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex">
                <span>
                  <img
                    alt="RHS emergency Contact"
                    src={medicationConIcon} className="icon"
                  ></img>
                </span>
                <span>
                  {BannerContent.ServiceBanner["Service-6"]["service-desc"]}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 btn-container">
              <Button primaryButton={false} className="extend-btn">Consult Now</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
