import { BrowserRouter, Routes, Route } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
  import { fab } from '@fortawesome/free-brands-svg-icons';
  import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
  import {faWhatsapp,faFacebook,faInstagram} from  '@fortawesome/free-brands-svg-icons'
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/jquery/dist/jquery.slim.min.js";
import "../node_modules/popper.js/dist/popper.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import HomepageModule from "./Page/HomepageModule";
import About from "./Page/AboutUsPage";
import DoctorSearchPage from "./Page/DoctorSearchPage";
import DoctorConsultationPage from "./Page/DoctorConsultationPage";
import TermsAndCondition from "./Page/Terms-and-Condition.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomepageModule />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/doctorsearch" element={<DoctorSearchPage />}></Route>
          <Route exact path="/doctorDetail" element={<DoctorConsultationPage />}></Route>
          <Route exact path="/terms&conditions" element={<TermsAndCondition />}></Route>
        </Routes>
      </BrowserRouter>
      {/* <HomepageModule></HomepageModule> */}
    </>
  );
}

export default App;
library.add(fab,faWhatsapp,faFacebook,faInstagram)
