import React from "react";
import Input from "../../UI/UIComponent/Input";
import Button from "../../UI/UIComponent/Button.js";
import './quickBooking.scss';

export default function QuickBooking(props) {
  const listofItems = props.thingsToRemember;
  const handleonBlur = () => {};
  const handleOnInputChange = () => {};
  return (
    <div className="quick-booking">
      <div className="container p-0">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="heading">{props.heading}</h2>
            <div className="things-to-remember">
              <ul>
                {listofItems.map((item) => (
                  <li>
                    <label><b>{item.label_text}</b></label>
                    <p>{item.para_text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 custom-col">
            <div className="form-section">
              <div className="heading-section">
                <h2 className="heading">We are here to help you!</h2>
                <span className="subheading">
                  Get Instant Callback in few mins
                </span>
              </div>

              <form>
                <Input
                  inputPlaceholder="Enter Your Full Name"
                  handleInputBlur={handleonBlur}
                  handleonChange={handleOnInputChange}
                ></Input>
                <Input
                  inputPlaceholder="Enter Your Contact No"
                  handleInputBlur={handleonBlur}
                  handleonChange={handleOnInputChange}
                ></Input>
                <div>
                  <Input
                    inputPlaceholder="Enter State"
                    handleInputBlur={handleonBlur}
                    handleonChange={handleOnInputChange}
                    isDisabled="true"
                  ></Input>
                  <Input
                    inputPlaceholder="Enter Pin"
                    handleInputBlur={handleonBlur}
                    handleonChange={handleOnInputChange}
                  ></Input>
                </div>
                <Button primaryButton={true}>Book an appointment</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
