import React from 'react'
import Image from '../UIComponent/Image'

export default function NonCarouselBanner(prop) {
    const bannerImage = prop.ImageSrc;
    const bannerImageAltText = prop.imageAlt
  return (
    <div className='non-carousel-banner'>
        <Image imageSrc={bannerImage} imageAltimageAltText={bannerImageAltText} imageClass='w-100'></Image>
    </div>
  )
}
